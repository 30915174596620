<template>
    <div class="change-password">

        <div class="container">
        <div class="pt-3 pb-3 pl-3 pr-3">
        
        <form @submit.prevent="onSubmitChange" v-if="screen === 'change'" autocomplete="off">
            <div class="form-group">
                <p class="h5 text-center">{{ $t('change_password.new_pass') }}</p>
            </div>
            <div class="form-group">
                <label for="inputPassword1">{{ $t('LoginModal.password') }}</label>
                <input type="password" class="form-control" name="password" id="inputPassword1" :placeholder="$t('LoginModal.password')" v-model.trim="change.password">
                <template v-if="$v.change.password.$error">
                <small class="form-text text-warning" v-if="!$v.change.password.required">{{ $t('LoginModal.enter_your_password') }}</small>
                <small class="form-text text-warning" v-if="!$v.change.password.min">{{ $t('LoginModal.at_least_8_characters') }}</small>
                </template>
            </div>
            <div class="form-group">
                <label for="inputConfirmPassword1">{{ $t('LoginModal.confirm_password') }}</label>
                <input type="password" class="form-control" name="confirmPassword" id="inputConfirmPassword1" :placeholder="$t('LoginModal.confirm_password')" v-model.trim="change.confirmPassword">
                <template v-if="$v.change.confirmPassword.$error">
                <small class="form-text text-warning" v-if="!$v.change.confirmPassword.required">{{ $t('LoginModal.type_your_password_again') }}</small>
                <small class="form-text text-warning" v-if="!$v.change.confirmPassword.sameAsPassword">{{ $t('LoginModal.passwords_must_match') }}</small>
                </template>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-zhako w-100">
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="spinnerVisible"></span>
                    {{ $t('change_password.change') }}
                </button>
            </div>
        </form>

        <div v-if="screen === 'activate'">
            <p class="h5 text-center">{{ $t('change_password.new_pass') }}</p>
            <p>{{ $t('LoginModal.activate_message') }}</p>
        </div>

        </div>
        </div>

                    <!-- <p ng-show="showError"><span style="font-size:14px; color:red">{{translation.change_password.pass_not_equal}}</span><br></p>
                </div>
                <form id="changePasswordForm" style="margin-left: auto; margin-right: auto; width: 300px">
                    <div class="form-group">
                        <input type="password" class="form-control" ng-model="param.password" name="password"
                               placeholder="Пароль">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" ng-model="param.confirmPassword"
                               name="confirmPassword"
                               placeholder="{{translation.change_password.again}}">
                    </div>
                    <button type="button" class="btn btn-primary" ng-click="changePassword(param)">{{translation.change_password.accept}}</button>
                </form> -->
    </div>
</template>

<style lang="scss" scoped>

.change-password {
    width:500px;
    margin: 0 auto;
    background-color: #553DB4;
    color: white;

    .container {
        background-color: #553DB4;
        color: white;

        a {
            color: white;
            text-decoration: underline !important;
        }
    }
}
</style>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
    name: "ChangePassword",
    data() {
        return {
            screen: 'change'
            ,spinnerVisible: false
        ,change: {
            password: null,
            confirmPassword: null,
        }
        };
    },
    validations: {
        change: {
            password: { required, min: minLength(8) },
            confirmPassword: { required, sameAsPassword: sameAs('password') },
        }
    },
    mounted() {
        this.onShow();
    },
    methods: {
        onShow() {
            if (this.$route.params.token) {
                this.change.token = this.$route.params.token;
            }
        },
        onSubmitChange() {
            this.$v.change.$touch();
            if (! this.$v.change.$invalid) {
                this.showSpinner();
                this.$store.dispatch("CHANGE_PASSWORD", this.change)
                    .then(() => {
                        this.hideSpinner();
                        this.changeScreen('activate');
                        this.$toast.success("Ваш пароль сменен.");
                        this.$router.push("/");
                    })
                    .catch(e => e.message && this.$toast.error(e.message) && this.hideSpinner())
                    ;
            }
        },
        changeScreen(scr) {
            this.screen = scr;
        },
        showSpinner() {
            this.spinnerVisible = true;
        },
        hideSpinner() {
            this.spinnerVisible = false;
        },
    }
}
</script>